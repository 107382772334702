#takeawayPage {
  --img-size-agua: 5.2rem;
  --img-size-take: 4.3rem;
  --repartidorProfile-bgColor: #000000;

  height: 8.55rem;
  background: linear-gradient(
    to top right,
    rgb(0, 0, 92),
    rgba(1, 1, 209, 0.849)
  );
  .header {
    color: white;
    &.repartidorProfile {
      background: linear-gradient(to top right, #000000, #202020f1);
    }
    &.playeroProfile {
      background: linear-gradient(to top right, #004b04, #007a06ce);
    }
    .logos {
      .logo_agua img {
        width: var(--img-size-agua);
        height: var(--img-size-agua);
      }
      .logo_take img {
        width: var(--img-size-take);
        height: var(--img-size-take);
      }
    }
    .titleContainer {
      background-color: rgba(1, 1, 209, 0.76);
      font-size: 1.25rem;
      color: #ffffffab;
      font-size: 1.5rem;
      &.customerProfile {
        background-color: rgba(1, 1, 209, 0.76);
      }
      &.repartidorProfile {
        background-color: #5263992f;
      }
      &.playeroProfile {
        background-color: #00000045;
      }
      .title {
        white-space: nowrap;
      }

      .admin {
        color: #a0bddd;
        width: 4rem;
        background-color: transparent;
        border-style: none;
        &:focus {
          border-color: rgb(255, 144, 0);
          box-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset,
            0 0 8px rgba(255, 144, 0, 0.6);
          outline: 0 none;
        }
        &::placeholder {
          color: rgba(115, 163, 236, 0.685);
        }
      }
      .salir {
        line-height: 10px;
      }
    }
  }
  .botoneraPlayero {
    //position: absolute;
    // bottom: 27px;
    // left: 60px;
    // right: 60px;
    padding-bottom: 15px;
    .button {
      margin-bottom: 10px;
    }
  }
}

#shopping_consumidor_playero {
  --btnMinusAdd-color: #007bffb0;
  --btnMinusAdd-color-disabled: #27313b4d;
  --sticker-color: #091a2ba2;

  background-color: #f5f5f5;
  .products {
    color: rgb(0, 0, 92);
    .product {
      font-size: 1.2rem;
      background-color: white;
      width: 170px;
      border-radius: 10px !important;
      &.active {
        opacity: 1;
        border-color: #091a2b36 !important;
      }
      &.inactive {
        opacity: 0.83;
      }
      .stockSticker {
        position: absolute;
        margin-left: 128px;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 0 7px 0 7px;
        &.stockMin {
          color: #ff6a06e3;
          border: 1px solid #ff6a06e3;
        }
        /* &.agotado {
          background-color: #ff0606e3;
          color: white;
          border: 0px;
        } */
        &.agotado {
          color: #ff0606e3;
          border: 1px solid #ff0606e3;
          font-weight: bold;
        }
      }
      .envaseSticker {
        position: absolute;
        margin-left: 128px;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 0 7px 0 7px;
        &.naranja {
          color: #ff6a06e3;
          border: 1px solid #ff6a06e3;
        }
        &.agotado {
          color: #ff0606e3;
          border: 1px solid #ff0606e3;
        }
      }
      .limiteVaciosSticker {
        position: absolute;
        //margin-left: 112px;
        // margin-top: 45px;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 7px 0 7px 0;
        &.naranja {
          color: #ff6a06e3;
          border: 1px solid #ff6a06e3;
        }
        &.agotado {
          color: #ff0606e3;
          border: 1px solid #ff0606e3;
        }
      }
      .debeSticker {
        position: absolute;
        width: 55px;
        margin-left: 105px;
        justify-content: end;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 0 7px 0 7px;
        &.stockMin {
          color: #ff6a06e3;
          border: 1px solid #ff6a06e3;
        }
        &.debe {
          background-color: #ff0606e3;
          color: white;
          border: 0px;
        }
        &.debeIzq {
          margin-left: 0px;
          border-radius: 7px 0 7px 0;
          width: 45px;
          background-color: transparent;
          color: #ff0606e3;
          border: 1px solid #ff0606e3;
        }
      }
      .imgContainer {
        img {
          width: 70%;
          height: 70%;
        }
      }
      .description {
        font-size: 1rem;
        line-height: 1.2rem;
        height: 2.2rem;
      }
      .price {
        font-size: 1.08rem;
        line-height: 1.8rem;
        color: rgb(0, 0, 255);
        &.envases {
          background-color: rgb(213, 248, 248);
          font-size: 0.8rem;
        }
      }
      .botonera {
        border-style: solid;
        border-width: 1px;
        height: 36px;
        border-radius: 10px;
        &.inactiva {
          border-color: #091a2b36;
        }
        &.activa {
          border-color: var(--btnMinusAdd-color);
          background-color: azure;
        }
        .minusBtn {
          color: var(--btnMinusAdd-color);
          border-style: none;
          background-color: transparent;
          margin: 0.25rem 0 0.5rem 0.4rem;
          outline: 0;
          &.inactivo {
            color: var(--btnMinusAdd-color-disabled);
          }
        }
        .contentCant {
          width: 3rem;
          align-content: center;
          input {
            padding: 0.28em 0.2em 0.2em 0.2em;
            font-size: 1.3rem;
            text-align: center;
            height: 30px;
            background-color: white;
            border: none;
            box-shadow: none;
            margin: 0.1rem 0 0 0;
          }
        }
        .addBtn {
          color: var(--btnMinusAdd-color);
          border-style: none;
          background-color: transparent;
          margin: 0.25rem 0.4rem 0.5rem 0;
          outline: 0;
          &.disabled {
            color: var(--btnMinusAdd-color-disabled);
          }
        }
      }
    }
  }
  .totalArticles {
    font-size: 0.9rem;
    vertical-align: middle !important;
  }
}

#shopping_repartidor {
  --btnMinusAdd-color: #007bffb0;
  --btnMinusAdd-color-disabled: #27313b4d;
  --sticker-color: #091a2ba2;
  --sticker-color-tendra: #0069d1b0;

  background-color: #f5f5f5;
  .products {
    color: rgba(0, 0, 0, 0.808);
    .product {
      font-size: 1.2rem;
      background-color: white;
      width: 170px;
      border-radius: 10px !important;
      &.active {
        opacity: 1;
        border-color: #091a2b36 !important;
      }
      &.inactive {
        opacity: 0.83;
      }
      .stockSticker {
        position: absolute;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 7px 0 7px 0;
        &.enRojo {
          color: #ff2b06e3;
          border: 1px solid #ff2b06e3;
        }
      }
      .stockStickerPlus {
        //Tendrá
        position: absolute;
        margin-left: 112px;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color-tendra);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color-tendra);
        border-radius: 0 7px 0 7px;
        &.enRojo {
          color: #ff2b06e3;
          border: 1px solid #ff2b06e3;
        }
      }
      .debeSticker {
        position: absolute;
        width: 55px;
        margin-left: 105px;
        justify-content: end;
        line-height: 1.1rem;
        background-color: transparent;
        color: var(--sticker-color);
        font-size: 0.9rem;
        padding: 2px 3px 2px 3px;
        border: 1px solid var(--sticker-color);
        border-radius: 0 7px 0 7px;
        &.debe {
          background-color: #ff0606e3;
          color: white;
          border: 0px;
        }
      }

      .imgContainer {
        img {
          width: 70%;
          height: 70%;
        }
      }
      .description {
        font-size: 1rem;
        line-height: 1.2rem;
        height: 2.2rem;
      }
      .price {
        font-size: 1.08rem;
        line-height: 2rem;
        color: rgb(0, 0, 255);
      }
      .botonera {
        border-style: solid;
        border-width: 1px;
        height: 36px;
        border-radius: 10px;
        &.inactiva {
          border-color: #091a2b36;
        }
        &.activa {
          border-color: var(--btnMinusAdd-color);
          background-color: azure;
        }
        .minusBtn {
          color: var(--btnMinusAdd-color);
          border-style: none;
          background-color: transparent;
          margin: 0.25rem 0 0.5rem 0.4rem;
          outline: 0;
          &.inactivo {
            color: var(--btnMinusAdd-color-disabled);
          }
        }
        .contentCant {
          width: 3rem;
          align-content: center;
          input {
            padding: 0.28em 0.2em 0.2em 0.2em;
            font-size: 1.3rem;
            text-align: center;
            height: 30px;
            background-color: white;
            border: none;
            box-shadow: none;
            margin: 0.1rem 0 0 0;
          }
        }
        .addBtn {
          color: var(--btnMinusAdd-color);
          border-style: none;
          background-color: transparent;
          margin: 0.25rem 0.4rem 0.5rem 0;
          outline: 0;
          &.disabled {
            color: var(--btnMinusAdd-color-disabled);
          }
        }
      }
    }
  }
  .totalArticles {
    font-size: 1rem;
    vertical-align: middle !important;
  }
}

#carritoDetail {
  background-color: white;
  .header1 {
    padding: 0;
    .header2 {
      padding: 0;
      ·header3 {
        padding-left: 0;
        background-color: #007bff;
      }
    }
  }
  .productDetail {
    line-height: 1.1rem;
    color: #212529e8;
    .price {
      white-space: nowrap;
    }
    .stock {
      white-space: nowrap;
      &.disponible {
        opacity: 0.7;
      }
      &.minimo {
        color: #ff6a06e3;
      }
      &.agotado {
        color: red;
      }
    }
  }
  .botonera {
    --btnMinusAdd-color: #007bff9a;
    --btnMinusAdd-color-disabled: #091a2b36;
    --botonera-border-color: #091a2b36;

    border-style: solid;
    border-width: 1px;
    height: fit-content;
    border-radius: 10px;
    width: 28px;
    &.inactiva {
      border-color: var(--botonera-border-color);
    }
    &.activa {
      border-color: var(--btnMinusAdd-color);
      background-color: azure;
    }
    .btnMinusAdd {
      box-shadow: none;
      color: var(--btnMinusAdd-color);
      border-style: none;
      background-color: transparent;
      &:focus {
        outline: none;
      }
      &.del {
        color: #ff00009a;
      }
      &.disabled {
        color: var(--btnMinusAdd-color-disabled);
      }
    }
    .cantidad {
      color: rgb(0, 0, 0);
      &.repartidor {
        font-weight: bold;
        font-size: 1.3rem;
        margin: 0 !important;
        background-color: rgb(201, 248, 248);
        width: 100%;
        text-align: center;
      }
    }
  }
  .card-img-top2 {
    display: block;
    width: 80px;
    margin: 0 auto;
  }


  
  .botoneraTd {
    padding: 11px 14px 11px 16px;
  }
  .subtotalTd {
    white-space: nowrap;
    width: 72px;
    padding: 0;
  }
  .debeEnvases {
    color: red;
    text-align: center;
  }

  .total {
    white-space: nowrap;
    background-color: azure;
    margin-left: 10px;
  }
  .totalArticles {
    padding-left: 27px;
  }

  .botonFinalizar {
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    &.repartidor {
      background-color: #000000dd;
    }
    &.playero {
      background-color: #04aa0c;
    }
  }
}

#headerCarritoDetail {
  .title span {
    font-size: 1.25rem;
  }
}

.comprobante {
  //recibo o remito
  background-color: #c1d4e9ef;
  padding: 15px 6px 8px 12px;
  border-radius: 9px;
  &.pagado {
    background-color: #c1e9c82c;
    border: 1px solid #007700e3;
  }
  &.pendiente {
    background-color: #c1e9c82c;
    border: 1px solid #ff0606;
  }
  &.cancelado {
    background-color: #c1e9c82c;
    border: 1px solid #ff0606;
  }
  &.aceptado {
    background-color: #c1e9c82c;
    border: 1px solid #007700e3;
  }
  &.noAceptado {
    background-color: #c4c4c4ef;
    border: 1px solid #ff0606;
  }
  &.efectivo {
    background-color: #c1e9c82c;
    border: 1px solid #007700e3;
  }
  &.mercadoPago {
    background-color: #b3d1ff2c;
    border: 1px solid #0a06ff;
  }
}
.reciboTitle {
  &.cancelado {
    color: red;
  }
  &.pagado {
    color: #000000;
  }
  &.pendiente {
    color: #df5555;
  }
}

.comprobanteSticker {
  position: absolute;
  width: 80px;
  margin-left: 10px;
  margin-top: -10px;
  justify-content: end;
  line-height: 1.5rem;
  font-size: 1rem;
  padding: 2px 3px 2px 3px;
  border-radius: 0 10px 0 10px;
  &.aceptado {
    color: #007700e3;
    background-color: #daf5da;
    border: 1px solid #007700e3;
  }
  &.pagado {
    color: #007700e3;
    background-color: #daf5da;
    border: 1px solid #007700e3;
  }
  &.pendiente {
    width: 90px;
    color: white;
    background-color: #ff0606;
    border: 0;
    //border: 1px solid #e70404c4;
  }
  &.noAceptado {
    width: 90px;
    background-color: #ff0606;
    color: white;
    border: 0px;
  }
  &.cancelado {
    width: 90px;
    background-color: #ff0606;
    color: white;
    border: 0px;
  }
  &.efectivo {
    color: #007700e3;
    background-color: #daf5da;
    border: 1px solid #007700e3;
  }
  &.mercadoPago {
    width: 108px;
    margin-top: -8px;
    background-color: #0603c4;
    color: white;
    border: 0px;
    line-height: 1.1rem;
    padding: 6px;
  }
}

.stickerSaldoDisp {
  position: absolute;
  width: 85px;
  margin-left: 3px;
  font-size: 1rem;
  padding: 2px 3px 2px 3px;
  border-radius: 5px 5px 5px 5px;
  color: #007700e3;
  background-color: #daf5da;
  //border: 1px solid #007700e3;
  height: 47px;
  line-height: 1.3rem;
  bottom: 125px;
}

.filaSwal {
  line-height: 1rem;
  text-align: start;
  margin: 0;
  font-size: smaller;
}
.subTotal {
  line-height: 1rem;
  //background-color: #c3c4f3;
  text-align: end;
  margin: 7px;
  font-size: smaller;
  font-weight: bold;
}

.listados {
  .divScrolleable {
    //max-height: 31.5rem;
    overflow: auto;
  }
  .recibosTable {
    max-height: 32rem;
    overflow: auto;
    position: relative;
    display: block;
  }
  .recibosTitle {
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: #d5d5d5 0px 0px 1px 0px;
  }
  .filaRecibo {
    line-height: 1.1rem;
    &:active {
      background-color: #84c988;
    }
    &.pendiente {
      color: #df6e12a2;
    }
    &.cancelado {
      color: #4e4e4e;
    }
    &.afectado {
      color: #4e4e4ea2;
    }
    &.anulado {
      color: #4e4e4ea2;
      text-decoration: line-through;
    }
    td {
      font-size: 0.9em;
    }
  }
}

.envase {
  filter: opacity(80%) grayscale(72%);
}

.inputCantidad[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
}

.inputCantidad::-webkit-inner-spin-button{
  display: none;
}