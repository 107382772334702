// blue
$anBlue: rgb(0, 46, 108);

// ligth gray
$anLightgray: rgb(85, 85, 89);

// dark gray
$anDarkGray: rgb(16, 24, 31);

// background color
$bg-color: #002e6d;

$an-info: #0069a6;

$an-buttom-info: #009bde;
