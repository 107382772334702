#consumptions {
  margin-top: 17px;
  // circular char
  #circular-char {
    @media (max-width: 768px) {
      .CircularProgressbar {
        height: 200px;
      }
    }
  }

  // zone resume
  #consumption-resume {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }

  background: white;
  padding: 20px;
}
