@import './colors.scss';
@import './fonts.scss';

#login {
  background-color: $bg-color;
  min-height: 100vh;
}

#login .container #login-row #login-column #login-box {
  margin-top: 120px;
  max-width: 600px;
  border: 1px solid #9c9c9c;
  background-color: #eaeaea;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}
#login
  .container
  #login-row
  #login-column
  #login-box
  #login-form
  #register-link {
  margin-top: -85px;
}

#login {
  .btn.an-info {
    background-color: $an-buttom-info;
  }

  #login-box {
    margin-bottom: 50px;
  }

  .logo-inicio {
    display: block;
    margin: 0 auto;
    padding-top: 50px;
    width: 130px;
  }
}

.an-popover {
  font-family: $halvettCondensed;

  h3 {
    color: $bg-color;
  }
}
