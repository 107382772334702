@import './colors.scss';

.loading_simple {
  display: block;
  margin: auto;
  width: 50px;
  height: 50px;
  border: 5px solid rgba(173, 214, 248, 0.34);
  border-radius: 50%;
  border-top-color: #2475c7;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}
.center-screen {
  position: fixed;
  text-align: center;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -50%);
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}