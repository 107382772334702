@import './colors.scss';
@import './fonts.scss';

.an-footer {
  font-family: $halvettCondensedBold;
  color: white;
  font-size: 15px;
  height: 150px;
  background-color: $anDarkGray;
}

.with-border-up-blue {
  background: white;
  * {
    background: white;
  }
}
