@import './colors.scss';

.text-dark-info {
  color: $bg-color;
}

.logo-azul {
  width: 180px;
  display: block;
  margin: 0 auto;
}

.btn-perfil {
  width: 83px;
  height: 30px;
  display: block;
}

.with-border-down-blue {
  background: white;
   position: fixed;
   z-index: 10;
   width: 100%;
   margin-top: -50px;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: $bg-color;
  text-shadow: 0 0 1px #000;
}

.navbar-light .navbar-nav .nav-link {
  color: $anLightgray;
}
