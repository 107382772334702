.shopping {
  display: flex;
  flex-wrap: wrap;

  .shopping-card {
    flex: 0 0 80%;
    margin: 0 auto;
    display: block;

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      flex: 0 0 45%;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      flex: 0 0 30.3%;
    }

    .card-img-top1 {
      display: block;
      width: 50px;
      margin: 0 auto;
    }

  }
  .shopping-fixed {
    position: fixed;
    bottom: 85px;
    left: 10px;
    z-index: 10;
  }
}
