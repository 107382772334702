/* import the necessary Bootstrap files */
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';

// brand
@import './assets/fonts/stylesheet.css';

// COLORS
@import './scss/colors.scss';

// fonts name
@import './scss/fonts.scss';

$theme-colors: (
  primary: $anBlue,
  light: $anLightgray,
  dark: $anDarkGray,
  info: $an-info,
);

body,
* {
  font-family: $halvettCondensed;
  font-size: 16px;
  button {
    font-family: $halvettCondensedBold !important;
    font-weight: bold;
  }

  .btn {
    font-family: $halvettCondensedBold !important;
    font-weight: bold;
  }

  table {
    font-family: $halvettCondensed !important;
    font-size: 16px;
  }
}

body {
  background-color: #f2f2f2;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $halvettCondensedBold !important;
}
.btn {
  font-family: $halvettCondensedBold;
}

.with-border-up-blue {
  border-top: 5px solid $an-buttom-info;
}

.with-border-down-blue {
  border-bottom: 5px solid $an-buttom-info;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.banner-pedido {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: $anBlue;
  cursor: pointer;
}

.shopping-car {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* finally, import Bootstrap */
@import '../node_modules/bootstrap/scss/bootstrap.scss';

@import './scss/footer.scss';
@import './scss/buttons.scss';
@import './scss/bootstrap-table.scss';
@import './scss/takeaway/takeaway.scss';
@import "./scss/loading";
