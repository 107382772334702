#carrousel {
    .carousel-inner {

        .carousel-item {
            img{
                margin: 0 auto;
                width: 90%;
            }
            
        }
    }
}