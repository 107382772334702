#payment-action {
    // boton de pagos
    margin-top: 30px;
    @media (max-width: 768px) {
        #btn-payment {
            width: 80%;
        }
    }

    #btn-payment {
        width: 300px;
    }
    
}