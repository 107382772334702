@import './colors.scss';
@import './fonts.scss';

body {
  background-color: #ffffff;
}

@page {
  size: 210mm 297mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 0mm;
}

.page {
  margin: 0;
  border: initial;
  border-radius: initial;
  width: initial;
  min-height: initial;
  box-shadow: initial;
  background: initial;
  page-break-after: always;
  font-family: $halvettCondensedBold;
}

svg {
  width: 100%;
}

.container {
  width: 95%;
}

.logo {
  display: block;
  width: 150px !important;
}


.receipt {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  height: 1550px;
  max-height: 1550px;
  -webkit-print-color-adjust: exact;
  font-family: $halvettCondensed;

  .receipt-header {
    height: 30%;
    padding: 10px;
    background-color: #eff0f1;
    small{
      font-family: $halvettCondensedBold !important;
    }
  }

  @media (max-width: 600px) {
    .receipt-header {
      overflow: auto;
      height: 30%;
      padding: 10px;
      background-color: #eff0f1;
      font-family: 'HalvettCondensedBold';
    }

    .receipt-body {
      height: auto !important;
    }
  }

  .receipt-body {
    height: 40%;
  }
  .receipt-footer {
    background-color: '#dedede';
    font-family: $halvettCondensedBold;

    img {
      width: 251px;
    }
    small {
      font-size: 7px;
      width: 100%;
      text-align: center;
      line-height: 7px;
      margin: 0;
      display: block;
      margin-top: 5px;
    }
  }
}

.text-ml {
  color: #009ee3;
}

.logo-ml {
  display: block;
  width: 75px !important;
  height: 22px;
}

.static-print {
  position: fixed;
  top: 10px;
  left: 10px;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  .container {
    width: 95%;
    border: transparent;
  }
}

.remitos-section {
  display: contents;
}
