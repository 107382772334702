@font-face {
  font-family: 'Univers 55';
  src: url('Univers.woff2') format('woff2'), url('Univers.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalvettCondensedBold';
  src: url('HalvettCondensedBold.woff2') format('woff2'),
    url('HalvettCondensedBold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HalvettCondensed';
  src: url('HalvettCondensed.woff2') format('woff2'),
    url('HalvettCondensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
